import Link from 'next/link';
import styled from '@xstyled/styled-components';
import SearchQueryResults from './Results';
import Loader from '@/UI/Loader';
import { useTranslation } from 'next-i18next';

const SearchResults = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 0px 17px -1px rgba(227, 233, 236, 0.42);
  border-radius: 15px;
  z-index: 12;

  a:last-child {
    padding-top: 1rem;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      color: teal-200;
    }
  }
`;

export default function Dropdown({
  searchTerm,
  searchResults,
  setShowDropdown,
  loading
}) {
  const { t } = useTranslation();

  return (
    <SearchResults>
      {loading ? (
        <Loader mt={-12} />
      ) : (
        <SearchQueryResults
          searchResults={searchResults}
          imageSize={{ width: 80, height: 100 }}
          slice={5}
          setShowDropdown={setShowDropdown}
        />
      )}
      {!loading && (
        <Link href="/otsing/[term]" as={`/otsing/${searchTerm}`}>
          <a>{t('search.all_results')}</a>
        </Link>
      )}
    </SearchResults>
  );
}
